<template>
    <div class="service">
        <section>
            <div class="container">
                <div class="title">
                    <h1>{{ $t('page.service.basic.title') }}</h1>
                    <p>{{ $t('page.service.basic.intro') }}</p>
                </div>
                <div class="four-row">
                    <div class="item">
                        <h2>{{ $t('page.service.basic.community.title') }}</h2>
                        <p>{{ $t('page.service.basic.community.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.basic.forum.title') }}</h2>
                        <p>{{ $t('page.service.basic.forum.description') }}</p>
                        <a href="https://ask.fairygui.com" target="_blank" rel="noopener noreferrer"><span>{{ $t('page.service.basic.forum.button') }}</span><i class="iconfont">&#xe638;</i></a>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.basic.open_source.title') }}</h2>
                        <p>{{ $t('page.service.basic.open_source.description') }}</p>
                        <a href="https://github.com/fairygui/" target="_blank" rel="noopener noreferrer"><span>{{ $t('page.service.basic.open_source.button') }}</span><i class="iconfont">&#xe638;</i></a>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.basic.wechat_oa.title') }}</h2>
                        <p>{{ $t('page.service.basic.wechat_oa.description') }}<br/><img src="@/assets/social/woa-qrcode.jpg" alt=""></p>
                    </div>
                </div>
            </div>
        </section>
        <section class="enterprise">
            <div class="container">
                <div class="title">
                    <h1>{{ $t('page.service.enterprise.title') }}</h1>
                    <p>{{ $t('page.service.enterprise.intro') }}</p>
                </div>
                <div class="four-row">
                    <div class="item">
                        <h2>{{ $t('page.service.enterprise.contact.title') }}</h2>
                        <p>{{ $t('page.service.enterprise.contact.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.enterprise.bug_fix.title') }}</h2>
                        <p>{{ $t('page.service.enterprise.bug_fix.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.enterprise.resource.title') }}</h2>
                        <p>{{ $t('page.service.enterprise.resource.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.enterprise.optimization.title') }}</h2>
                        <p>{{ $t('page.service.enterprise.optimization.description') }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="source-code">
            <div class="container">
                <div class="title">
                    <h1>{{ $t('page.service.auth_code.title') }}</h1>
                    <p>{{ $t('page.service.auth_code.intro') }}</p>
                </div>
                <div class="four-row">
                    <div class="item">
                        <h2>{{ $t('page.service.auth_code.unity.title') }}</h2>
                        <p>{{ $t('page.service.auth_code.unity.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.auth_code.system.title') }}</h2>
                        <p>{{ $t('page.service.auth_code.system.description') }}</p>
                    </div>
                    <div class="item">
                        <h2>{{ $t('page.service.auth_code.forever.title') }}</h2>
                        <p>{{ $t('page.service.auth_code.forever.description') }}</p>
                    </div>
                    <div class="item">
                        <!-- 这个空 item 用于平衡布局，请勿删除 -->
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Service'
}
</script>

<style lang="scss">
.service {
    section {
        padding: 70px 0;

        .title {
            width: 50%;
            margin-bottom: 50px;

            h1 {
                font-size: 48px;
                margin: 0;
                margin-bottom: 17px;
                line-height: 1;
            }
            p {
                margin: 0;
                font-size: 24px;
                line-height: 1.4;
                opacity: .8;
            }
            @media (max-width: 768px) {
                width: 75%;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}

.four-row {
    .item {
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 24px;
            margin: 0;
        }
        p {
            font-size: 14px;
            line-height: 1.75;
            color: #555555;
            height: 1%;
            flex-grow: 1;
            margin-bottom: 0;
            white-space: pre-wrap;

            img {
                width: 128px;
                margin-left: -6px;
            }
        }
        a {
            display: flex;
            text-decoration: none;
            color: var(--p);
            margin-top: 1em;
            padding: 7px 15px;
            border-radius: 6px;
            background: rgba(#FF971A, .1);
            align-items: center;

            span {
                display: block;
                width: 1%;
                flex-grow: 1;
            }
            &:hover {
                background: rgba(#FF971A, .2);
            }
        }
    }
}

.enterprise {
    color: #fff;
    background: #2488F9 url(../assets/decoration/enterprise-bg.png);
    background-size: cover;

    .four-row {
        .item {
            p {
                color: #fff;
                opacity: .8;
            }
        }
    }
}
.source-code {
    h1 {
        color: var(--p);
    }
}
</style>